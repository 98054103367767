<template lang="pug">
  include ../../../configs/template
  v-dialog(
    v-model="modal"
    max-width="500"
  )
    v-card(elevation="0")
      v-card-title {{ $t('sailorPhotoVerification') }}

      v-card-text.text-left
        +select('photo.status_document','listStatuses','status','labelName')

      v-card-actions
        v-btn(@click="modal = false" color="primary") {{ $t('back') }}
        v-btn(@click="setVerificationStatus" color="success") {{ $t('save') }}
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'SailorMainPhotoVerification',
  data () {
    return {
      id: this.$route.params.id,
      photo: {},
      status: null,
      modal: false
    }
  },
  watch: {
    modal (value) {
      if (value) this.photo = JSON.parse(JSON.stringify(this.$parent.chosenVerificationPhoto))
    }
  },
  computed: {
    ...mapState({
      labelName: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr'
    }),
    ...mapGetters(['statusById']),
    listStatuses () {
      let statusesArray = []
      const STATUSES = [34, 36, 41]
      STATUSES.forEach(status => statusesArray.push(this.statusById(status)))
      return statusesArray
    }
  },
  methods: {
    ...mapActions(['setSailorPhotoVerificationStatus', 'getSailorInformation']),
    async setVerificationStatus () {
      let data = {
        body: { status_document: this.photo.status_document },
        photoId: this.photo.id,
        sailorId: this.id
      }
      const response = await this.setSailorPhotoVerificationStatus(data)
      if (response) {
        await this.getSailorInformation(this.$route.params.id)
        this.modal = false
      }
    }
  }
}
</script>
