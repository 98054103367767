<template lang="pug">
div.sailorPhotos
  v-carousel(
    v-if="!editPhoto && sailorPhoto && sailorPhoto.length"
    :continuous="false"
    :show-arrows="true"
    delimiter-icon="mdi-minus"
    height="220px"
    width="220px"
  )
    v-carousel-item(
      v-for="photo in sailorPhoto"
      :key="photo.id"
    ).position-relative
      v-fade-transition
        div(
          v-if="!editPhoto && !mini"
          class="w-100 d-flex justify-end align-items-center pa-1"
        ).position-absolute.zIndex-1
          v-slide-x-reverse-transition
            div(v-if="showPhotoActions")
              v-tooltip(v-for="btn in listButtons" :key="btn.id" bottom)
                template(#activator="{ on }")
                  v-btn(
                    v-if="btn.checkAccess(photo)"
                    v-on="on"
                    @click="btn.action(photo)"
                    class="mx-2 my-1"
                    color="primary"
                    fab
                    dark
                    x-small)
                    v-icon {{btn.icon}}
                span {{ $t(btn.tooltipText) }}
          v-btn.mx-2(
              v-if="checkAccess('backOffice') || checkAccess('main-editSailorPhoto') || (checkAccess('main-verifySailorPhoto') && photo.status_document === 34)"
              @click="showPhotoActions = !showPhotoActions"
              color="primary"
              fab
              dark
              x-small)
            v-icon mdi-dots-vertical
      div.position-relative
        img(:src="photo.cropped || photo.origin" alt="sailorPhoto" height="220")
        span( v-if="photo.is_archived").secondary.white--text.pa-1.position-absolute.bottom-0.right-0 {{ $t('inArchive') }}
        span(v-else-if="photo.is_deleted").secondary.white--text.pa-1.position-absolute.bottom-0.right-0 {{ $t('deleted') }}
        span(v-else-if="[34, 36].includes(photo.status_document)").secondary.white--text.pa-1.position-absolute.bottom-0.right-0 {{ getDirectoryObject({ id: photo.status_document, value: 'statuses' })[labelName] }}
  img(v-if="sailorPhoto && !sailorPhoto.length && !editPhoto" src="@/assets/images/default.png" alt="sailorPhoto")

  v-btn(v-if="checkAccess('main-editSailorPhoto') && sailorPhoto && !sailorPhoto.length && !editPhoto && !mini" @click="startEditPhoto({url: null})" light).mt-5 {{$t('addedPhoto')}}
  DropZone( v-if="editPhoto" ref="profilePhoto" cropping :multiple="false" :existPhoto="photoEdited.url")
  v-btn.mt-5(v-if="checkAccess('main-editSailorPhoto') && editPhoto && !mini" @click="validatePhoto" light :loading="isLoadingPhoto") {{$t('savePhoto')}}
  v-btn( v-if="editPhoto" @click="editPhoto = false" light).mt-5 {{$t('cancel')}}
  SailorPhotoVerification(ref="sailorPhotoVerification")
</template>

<script>
import DropZone from '@/components/atoms/DropZone/DropZone.vue'
import SailorPhotoVerification from '@/views/Sailor/SailorMainInfo/SailorMainPhotoVerification.vue'
import { dataURLtoFile, deleteConfirmation, getDirectoryObject } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapActions, mapState } from 'vuex'
import { SUCCESS_CODE } from '@/configs/constants'

export default {
  name: 'SailorMainPhoto',
  props: {
    sailorPhoto: Array,
    mini: Boolean
  },
  components: {
    DropZone,
    SailorPhotoVerification
  },
  data () {
    return {
      id: this.$route.params.id,
      chosenVerificationPhoto: null,
      editPhoto: false,
      showPhotoActions: false,
      getDirectoryObject,
      dataURLtoFile,
      checkAccess,
      isVerification: false,
      listButtons: [
        { id: 1, checkAccess: () => checkAccess('backOffice'), icon: 'mdi-trash-can-outline', action: (photo) => this.removeSailorPhoto(photo), tooltipText: 'tooltip.delete' },
        { id: 2, checkAccess: (photo) => checkAccess('backOffice') && !photo.is_archived, icon: 'mdi-archive-arrow-down-outline', action: (photo) => this.archiveSailorPhoto(photo), tooltipText: 'tooltip.archiving-down' },
        { id: 3, checkAccess: (photo) => checkAccess('backOffice') && photo.is_archived, icon: 'mdi-archive-arrow-up-outline', action: (photo) => this.archiveSailorPhoto(photo), tooltipText: 'tooltip.archiving-up' },
        { id: 4, checkAccess: (photo) => checkAccess('main-verifySailorPhoto') && photo.status_document === 34, icon: 'mdi-check-decagram-outline', action: (photo) => this.openPhotoVerification(photo), tooltipText: 'tooltip.verify' },
        { id: 5, checkAccess: () => checkAccess('main-editSailorPhoto'), icon: 'mdi-pen', action: (photo) => this.startEditPhoto(photo), tooltipText: 'tooltip.edit' },
        { id: 6, checkAccess: () => checkAccess('main-editSailorPhoto'), icon: 'mdi-plus', action: () => this.startEditPhoto({ url: null }), tooltipText: 'tooltip.add' }
      ],
      isLoadingPhoto: false
    }
  },
  computed: {
    ...mapState({
      labelName: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr'
    })
  },
  watch: {
    sailorPhoto: {
      handler (val) {
        this.savePhoto = val
      },
      deep: true
    }
  },
  methods: {
    ...mapActions(['deleteSailorPhotoById', 'saveSailorPhoto', 'updateSailorPhoto', 'getSailorInformation', 'getFile']),
    validatePhoto () {
      setTimeout(() => {
        const { cropImg, imgSrc } = this.$refs.profilePhoto.$refs?.newCropper
        if (!imgSrc) this.$notification.warning('choosePhoto')
        else if (!cropImg) this.$notification.warning('takePhoto')
        else this.savePhoto(imgSrc, cropImg)
      }, 100)
    },

    async savePhoto (origin, cropped) {
      this.isLoadingPhoto = true
      const files = new FormData()
      files.append('sailor_pk', this.id)
      files.append('original_photo', dataURLtoFile(origin, 'origin'))
      files.append('cropped_photo', cropped)
      let method = this.photoEdited.url ? 'update' : 'save'
      const response = await this[`${method}SailorPhoto`]({ id: this.id, photoId: this.photoEdited?.id, body: files, responseType: 'blob' })
      console.log('response :>> ', response)
      if (SUCCESS_CODE.includes(response.code)) {
        this.readOnlyContact = true
        this.editPhoto = false
        console.log('this.isVerification :>> ', this.isVerification)
        console.log('this.$refs.sailorPhotoVerification :>> ', this.$refs.sailorPhotoVerification)
        if (this.isVerification) this.$refs.sailorPhotoVerification.modal = true
        // this.isVerification = false
      } else if (response.data[0] === 'The sailor with such passport data is exist') this.$notification.error('sailorExist')
      this.isLoadingPhoto = false
    },
    async startEditPhoto (photo) {
      this.editPhoto = true
      this.photoEdited = photo
    },

    cancelEdit () {
      this.editPhoto = false
      this.getSailorInformation(this.id)
    },

    openPhotoVerification (photo) {
      this.chosenVerificationPhoto = photo
      console.log('photo :>> ', photo)
      if (photo.cropped) {
        this.$refs.sailorPhotoVerification.modal = true
      } else {
        this.startEditPhoto(photo)
      }
      this.isVerification = true
    },

    removeSailorPhoto (photo) {
      deleteConfirmation(this).then(async (confirmation) => {
        if (confirmation) await this.deleteSailorPhotoById({ id: this.id, photoId: photo.id })
      })
    },

    archiveSailorPhoto (photo) {
      this.$swal({
        title: this.$t('warning'),
        text: this.$t('archivingConfirmation'),
        icon: 'info',
        buttons: [this.$t('cancel'), this.$t('confirm')],
        dangerMode: true
      }).then(async (confirmation) => {
        if (confirmation) {
          const body = { is_archived: !photo.is_archived }
          const response = await this.updateSailorPhoto({ id: this.id, photoId: photo.id, body, responseType: 'json' })
          if (SUCCESS_CODE.includes(response.code)) {
            if (photo.is_archived) this.$notification.success('removedFromArchive')
            else this.$notification.success('addedToArchive')
          } else this.$notification.error('notify.title.error')
        }
      })
    }
  }
}
</script>

<style scoped lang="sass">
.sailorPhotos
  max-width: 400px

.photoProfile
  height: 100% !important

.bottom-0
  bottom: 0px
.right-0
  right: 0px
</style>
